// Import the Critial JS files needed for every page
import * as mobileNav from "/js/mobile-nav.js";
import * as headerNav from "/js/header-nav.js";
import * as utilsNav from "/js/utils-nav.js";
import * as searchNav from "/js/search-nav.js";
import * as popups from "/js/popups.js";
import { scrollTo, lockout } from "/js/utilities.js";

const init = () => {
	smoothScroll();
	mobileNav.default();
	headerNav.default();
	utilsNav.default();
	searchNav.default();
	popups.default();

	const documentClass = document.documentElement.classList;
	documentClass.remove('no-js');
	documentClass.add('js');
	window.addEventListener('touchstart', () => {
		documentClass.add('touchevents');
		documentClass.remove('no-touchevents');
	}, { 'once': true })
}

window.popupData = {
	newsletter: {
		success: {
			title: "Thank you",
			message: "Your details are in our database and you are all signed up."
		}
	}
}

const smoothScroll = () => {
	$('a[href*="#"]:not(.js-scroll-done)').each(function() {
		var link = $(this);
		link.on('click', function() {
			if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
				var target = $(this.hash);
				target = target.length && target || $('[name="' + this.hash.slice(1) + '"]');
				if (target.length) {
					var targetOffset = target.offset().top;
					if (window.site.win.scrollTop() != targetOffset) {
						scrollTo(targetOffset);
					}
					return false;
				}
			}
		});
		// done
		link.addClass('js-scroll-done');
	});
}


const dynamicLoader = async () => {
	/*
		Files in `/js` subfolders are flattened by Gulp so the paths here dont need `/popup` or similar adding
	*/

	let loadedModules = [];
	// This is an array so you can set the order of being processed 
	const moduleLookup = [

		{
			class: 'a[data-analytics-event]',
			filename: 'analytics'
		},
		{
			class: '.cms',
			filename: 'cms'
		},
		{
			class: '.js-tabs-simple',
			filename: 'tabs-simple'
		},
		{
			class: '.js-search-block-wrapper',
			filename: 'search-block-wrapper'
		},
		{
			class: '.js-search-block-popup-buttons',
			filename: 'search-block-popup-buttons'
		},
		{
			class: '.js-booking-widget',
			filename: 'booking-widget'
		},
		{
			class: '.m-password__icon',
			filename: 'passwords'
		},
		{
			class: '.c-flights-properties__container',
			filename: 'flight-details'
		},
		{
			class: '.m-marketing-file-collection__name',
			filename: 'marketing-file-collections'
		},
		{
			class: '.m-side-nav',
			filename: 'side-nav'
		},
		{
			class: '.js-freeform-search',
			filename: 'freeform-search'
		},
		{
			class: '.agent-confirmed-checkbox',
			filename: 'booking-flow-agents'
		},
		{
			class: '.m-agents__filters',
			filename: 'agents'
		},
		{
			class: '.js-external',
			filename: 'external'
		},
		{
			class: '.js-flight-option',
			filename: 'flight-option'
		},
		{
			class: '.js-booking-airport-selectors, .js-booking-airport-toggle-action',
			filename: 'booking-airports'
		},
		{
			class: '.js-airport-picker-trigger',
			filename: 'airport-picker'
		},
		{
			class: '.js-destination-picker-trigger',
			filename: 'destination-picker'
		},
		{
			class: '.js-datepicker-placeholder',
			filename: 'date-picker'
		},
		{
			class: '.js-folks-selector-wrapper',
			filename: 'folks-selector'
		},
		{
			class: '.carousel',
			filename: 'carousel'
		},
		{
			class: '.m-banner-hero',
			filename: 'carousel'
		},
		{
			class: '.js-toggle-title',
			filename: 'toggle-block'
		},
		{
			class: '[data-recaptcha]',
			filename: 'recaptcha'
		},
		{
			class: '.js-gender-binary-reinforcer',
			filename: 'gender-binary-reinforcer'
		},
		{
			class: '.m-toggle-block',
			filename: 'toggle-block'
		},
		{
			class: '.js-age-checker',
			filename: 'age-checker'
		},
		{
			class: '.m-collection',
			filename: 'collection'
		},
		{
			class: '.m-blog-snippets',
			filename: 'blog-snippets'
		},
		{
			class: '.js-profile-form',
			filename: 'profile-form'
		},
		{
			class: '.js-accordion-trigger',
			filename: 'accordion'
		},
		{
			class: '.alternate',
			filename: 'image-paragraph'
		},
		{
			class: '.st-accord',
			filename: 'accordion'
		},
		{
			class: '.js-popup-gallery',
			filename: 'photo-gallery'
		},
		{
			class: '.m-home-banner',
			filename: 'home-banner'
		},

		{
			class: '.js-max-persons-adults',
			filename: 'max-persons'
		},
		{
			class: '.form-checks[data-limit]',
			filename: 'refine'
		},
		{
			class: '.m-press',
			filename: 'press'
		},
		{
			class: '.js-takeover-link',
			filename: 'takeover-link'
		},
		{
			class: '.m-tabs.js-internal',
			filename: 'tabs'
		},
		{
			class: '.js-login-form',
			filename: 'login-form'
		},
		{
			class: '.js-sticky-change-element',
			filename: 'sticky-change'
		},
		{
			class: '.js-newsletter-sign-up',
			filename: 'newsletter'
		},
		{
			class: '.js-add-to-wishlist',
			filename: 'wishlist-overlay'
		},
		{
			class: '#BookingViewModel_DetailsViewModel_Details_LeadPassenger_Address_AddressLookup',
			filename: 'lookup'
		},
		{
			class: '.js-nav-link-currency-item',
			filename: 'currency'
		},
		{
			class: '.m-map',
			filename: 'property-map'
		},
		{
			class: '.js-countdown-wrapper',
			filename: 'documentCountdown'
		},
		{
			class: '.js-summary-addition-radio, .js-summary-addition-text',
			filename: 'booking-summary'
		},
		{
			class: '.js-add-to-wishlist',
			filename: 'wishlist-overlay'
		},
		{
			class: '.js-accommodation-only-checkbox',
			filename: 'accommodation-only'
		},
		{
			class: '.js-dynamic-pager-page',
			filename: 'dynamic-pager'
		},
		{
			class: '#paymentForm',
			filename: 'tigerBay-payment'
		},
		{
			class: '.js-help-pin',
			filename: 'help-pin'
		},
		{
			class: '.js-ad-reg-help',
			filename: 'ad-reg'
		},
		{
			class: '.js-dynamic-search-replace-applied-filters',
			filename: 'applied-filters'
		},
		{
			class: '[data-mobile-search-trigger]',
			filename: 'mobile-search-nav'
		},
		{
			//video els
			class: 'a[href*="youtube.com/embed/"], a[href*="youtube.com/watch?v="], [href^="https://vimeo.com/"]',
			filename: 'video'
		},
		{
			class: '.js-search-map-container',
			filename: 'search-map'
		},
		{
			//Popups
			class: '.m-banner',
			filename: 'banner'
		},
		{
			class: '#inactivity-logout',
			filename: 'inactivityLogout'
		},
		{
			class: '#show-exit-form',
			filename: 'exit-form'
		},
		{
			class: '.js-booking-details',
			filename: 'booking-details'
		},
		{
			class: '.m-agents__reservations__search-btn',
			filename: 'agents-reservation-search'
		},
		{
			class: '.m-documents__download-btn',
			filename: "booking-documents-download"
		}

	]

	//First
	moduleLookup.forEach(module => {
		if (document.querySelector(module.class)) {
			loadedModules.push(module.filename)
		}
	});

	//Last
	loadedModules.push('dynamic-search');
	loadedModules.push('inactivityMonitor');
	loadedModules.push('forms');
	loadedModules.push('browser-back-navigation');
	loadedModules.push('emergency-message');

	if (loadedModules.length > 0) {

		const importedItems = await Promise.all(
			loadedModules.map(async itemName => {
				try {
					const imported = await import(`/js/${itemName}.js`);
					console.log(`Imported item: ${itemName}`);

					return imported.default();
				}
				catch (err) {
					console.log(err)
					console.log(`Failed to import item: ${itemName}`);
					return null;
				}
			})
		)
	}
}

const getPageTranslations = async (language) => {

	const imported = await import(`https://global.localizecdn.com/localize.js`);
	!function(a) { if (!a.Localize) { a.Localize = {}; for (var e = ["translate", "untranslate", "phrase", "initialize", "translatePage", "setLanguage", "getLanguage", "detectLanguage", "getAvailableLanguages", "untranslatePage", "bootstrap", "prefetch", "on", "off", "hideWidget", "showWidget", "getSourceLanguage"], t = 0; t < e.length; t++)a.Localize[e[t]] = function() { } } }(window);

	Localize.initialize({
		key: '5ddc274164735',
		autoApprove: true,
		defaultLanguage: language,
		autodetectLanguage: false
	});
}

document.addEventListener("DOMContentLoaded", () => {
	init();
	dynamicLoader()

	window.updateDynamicPhoneNumber = function() {
		if (typeof RulerAnalytics !== 'undefined') {
			RulerAnalytics.updateTelFromCache()
		}
	}
	window.updateDynamicPhoneNumber()

	const language = $('.js-language').data('language')?.toUpperCase();
	if (language && language !== 'EN') {
		getPageTranslations(language);
	}
})
